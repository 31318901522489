html {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

div, button, p, h1, h2, h3, h4, h5, h6, input, textarea, a, font {
  font-family: Ubuntu;
}
.scrollArea, .disableScrollBar {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.extendableInputArea {
  user-select: text;
}

.disableScrollBar {
  scrollbar-width: none;
}

.horizontalScrollBar {
  overflow-x: auto;
  overflow-y: hidden;
}

.horizontalScrollBar::-webkit-scrollbar {
  height: 5px;   
  background-color: #d5d5d5;
  border-radius: 20px;
}

.horizontalScrollBar::-webkit-scrollbar-thumb {
  background-color: #515151; 
  border-radius: 20px;
}

@media (max-width: 768px) {
  .horizontalScrollBar::-webkit-scrollbar {
    height: 10px;   
  }
}

.scrollArea::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.scrollArea::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  background-color: #d5d5d6;
}

.optionSelectScroll::-webkit-scrollbar-thumb {
  background-color: #515251;
  height: 40%;
}

.optionSelectScroll::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.searchHistoryScrollArea {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding-right: 0.52vw;
}

.searchHistoryScrollArea::-webkit-scrollbar {
  width: 5px
}

.searchHistoryScrollArea::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #515251;
  border-radius: 5px;
}

.searchHistoryScrollArea::-webkit-scrollbar-track {
  background-color: #DFDFDF;
  border-radius: 5px;
}

.testimonialScrollArea {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;  
}

.testimonialScrollArea::-webkit-scrollbar {
  width: 5px;
  -webkit-overflow-scrolling: touch;
}

.testimonialScrollArea::-webkit-scrollbar-track {
  background-color: #515251;
}

.testimonialScrollArea::-webkit-scrollbar-thumb {
  background-color: #515251;
  height: 3px;
}

.disableScrollBar::-webkit-scrollbar {
  display: none;
}

/* Customize Radio buttons*/
input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid #DEDEDE;
  outline: none;
  padding: 2px;
  cursor: pointer;
}

input[type="radio"]:checked {
  background-color: #01A9E0;
  -webkit-tap-highlight-color: transparent;
}

/*Customize Checkboxes*/
.customCheckBoxContainer {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.customCheckBoxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
  border: 0.15vw solid #DEDEDE;
}

.customCheckBoxContainer:hover input ~ .checkbox {
  background-color: #FFFFFF;
}

.customCheckBoxContainer input:checked ~ .checkbox {
  background-color: #01A9E0;
  border: 0.15vw solid #01A9E0;
}

.checkbox:after {
  content: "";
  position: absolute;
  display: none;
}

.customCheckBoxContainer input:checked ~ .checkbox:after {
  display: block;
}

.customCheckBoxContainer .checkbox:after {
  left: 0.27vw; 
  top: 0.07vw;
  width: 0.20vw;
  height: 0.41vw;
  border: solid white;
  border-width: 0 0.15vw 0.15vw 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .checkbox {
    border: 0.60vw solid #DEDEDE; 
  }
  .customCheckBoxContainer input:checked ~ .checkbox {
    border: 0.60vw solid #01A9E0;
  }
  .customCheckBoxContainer .checkbox:after {
    left: 1.2vw; 
    top: 0.4vw;
    width:  1vw;
    height: 2vw;
    border: solid white;
    border-width: 0 0.7vw 0.7vw 0;
    border-radius: 0.1vw;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .searchHistoryScrollArea {
    padding-right: 2.41vw;
  }
}

input::placeholder {
  color: #909090;
  font-family: Poppins;
}

.locationSearchInput::placeholder {
  font-family: Ubuntu;
}

.radio-button:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
}

input::-ms-reveal,
input::-ms-clear,
input[type="file"] {
  display: none;
}

.ul-bullets {
  width: 20px;
  height: 20px;
  background-color: #01A9E0;
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  -webkit-tap-highlight-color: transparent;
  transition: opacity .2s ease-in-out;
}

button:disabled{
  transform: unset !important;
  opacity: 0.4;
  cursor: auto !important;
}

button:active{
  transform: scale(0.95);
}

.slick-dots {
  top: 100%; 
  list-style-type: none;
}

.slick-dots li {
  margin-left: 2px !important;
  width: 10px !important;
  height: 10px !important
}

.slick-dots li button:before {  
  font-size: 10px !important;
}

.slick-dots li.slick-active button:before {
  color: #01A9E0 !important;
}

.slick-dots-white li {
  margin: 0 !important;
}

.slick-dots-white li button:before {
  color: #babac4 !important;
}

.google-maps {
  height: 100%;
}

.slick-slide {
  outline: none;
}

.slick-slide div {
  outline: none;
}

.link {
  color: #515151;
  text-decoration: none;
}

.link:hover{
  text-decoration: underline;
  color: #01A9E0;
}

.transition-enter {
  opacity: 0;
  z-index: 2;
}

.transition-enter-active {
  opacity: 1;
  transition: opacity 350ms ease-in-out;
  z-index: 2;
}

.transition-exit {
  opacity: 1;
}

.transition-exit-active {
  opacity: 0;
  transition: opacity 350ms ease-in-out;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.customMarkerStyle {
  position: absolute;
  padding: 0.41vw;
  margin-left: 0.7vw;
  margin-top: -1.3vw;
}

@media (max-width: 768px) {
    .customMarkerStyle {
      padding: 0.41vw;
      margin-left: 3.5vw;
      margin-top: -4vw;
    }
}

.white-svg .a {
  fill: white !important;
}

.jobviewDescription {
  font-family: 'Ubuntu';
  font-weight: 400;
  font-style: Regular;
  padding-top: 10px;

   font[size="3"]{
    font-size:  1.0333vw;
  }

  font[size="5"]{
    font-size:  1.302vw;
  }

  div[style="font-size: x-large; font-weight: bold;"]{
    line-height: 30px;
  }

  div[style="font-size: x-large;"]{
    line-height: 30px;
  }

  > div > ul > li > span[style="font-size: medium;"]{
    font-size:  1.0333vw !important;
  }

  ul {
    list-style: none;
    line-height: 1.5vw; 
  }
  
  ul > li {
    position: relative; 
    padding-left: 1.25vw; 
  }

  >div {
    margin-bottom:  0.40vw;
    line-height: 1.2vw;
  }

  >div > ul {
    line-height: 1.5vw;
  }

  ul > li::before {
    content: "•"; 
    color: #01a9e0; 
    position: absolute; 
    left: 0; 
    font-size: 1.145vw; 
  }
}

.jobviewDescription ul ::marker {
  color: #01a9e0 !important; 
}

@media (max-width: 768px) {
  
  .jobviewDescription {
    font-family: 'Ubuntu';
    font-weight: 400;
    font-style: Regular;
    margin-top: 0.8vw ;
 
    font[size="3"]{
      font-size: 3.864vw;
    }
  
    font[size="5"]{
      font-size:  6.038vw;
    }

    >div {
      margin-bottom:  0.3vw;
      line-height: 5.5vw;
    }
    
    > div > ul > li > span[style="font-size: medium;"]{
      font-size: 3.864vw !important;
    }

    >div > b > font[size="5"] {
       font-size: 4vw;
    }

    ul {
      list-style: none; 
    }

    ul > li::before {
      content: "•"; 
      color: #01a9e0; 
      position: absolute; 
      left: 0; 
      font-size: 5vw; 
    }

    >div > ul > li { 
      margin-bottom:  2.42vw;
      line-height: 4.5vw;
    }

    ul > li {
      position: relative; 
      padding-left: 5.79vw; 
      margin-bottom:  2.42vw;
      line-height: 4.5vw;
    }
  }
}

.termsConditions >p {
  font-family: 'Poppins' !important;
}

.termsConditions strong {
  font-size: 20px;
}

.termsConditions ul ::marker {
  color: #01A9E0;
}

.privacyPolicy strong {
  color: #595959
}

.privacyPolicy ol > li {
  margin-bottom: 3px
}

.privacyPolicy a {
  text-decoration: none;
  color: #01A9E0;
  word-wrap: break-word
}

.privacyPolicy th {
  text-align: center
}

.privacyPolicy table,
.privacyPolicy td,
.privacyPolicy th {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 7px
}

.privacyPolicy p,
.privacyPolicy h1,
.privacyPolicy h3,
.privacyPolicy h4,
.privacyPolicy a,
.privacyPolicy div {
  font-family: 'Poppins' !important
}

.privacyPolicy ul ::marker {
  color: #01A9E0;
}

.ql-container {
  border: unset !important;
  min-height: 18vw;
}

.ql-editor {
  min-height: 18vw;
}

.ql-undo {
  fill: #444;
}

.ql-redo {
  fill: #444;
}

@media (max-width: 768px) {

  .ql-toolbar {
    display: flex;
    overflow-y: visible !important;
    height: 10vw; 
  }

  .ql-snow .ql-formats {
    display: flex !important;
  }

  .ql-container {
    min-height: 51vw;
    height: unset;
  }

  .ql-editor {
    min-height: 51vw;
  }

  .iconWrapper {
    display: flex;
    overflow-x: scroll;
    height: 58.5vw;
    -ms-overflow-style: none;
    scrollbar-width: none; 
  }

  .iconWrapper::-webkit-scrollbar {
    display: none;
  }
}

.disabledInput {
  -webkit-text-fill-color: #0B132B;
  opacity: 1;
}
